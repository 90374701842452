import React, { FunctionComponent, useEffect } from 'react';
import { APP_DISPLAY_NAME } from '../../data/constants';

export const usePageTitle: FunctionComponent<string> = (pageTitle: string) => {
  useEffect(() => {
    const textToDisplay = `${pageTitle || ''} - ${APP_DISPLAY_NAME}`;
    document.title = textToDisplay;
  }, [pageTitle]);

  return null;
};
