import {
  // CloudFunctionFuncName,
  // getCloudFunctionUrl,
  getFirebaseAppAuth,
} from '../../firebase/firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  browserLocalPersistence,
} from 'firebase/auth';
import { RequestUserLoginWithDetails, RequestUserSignupWithDetails } from './types';
import { getDisplayTextForFirebaseError } from '../../firebase/utils';
import { FirebaseError } from 'firebase/app';

export const requestUserSignup = async (
  email: string,
  password: string,
): Promise<RequestUserSignupWithDetails> => {
  const firebaseAppAuth = getFirebaseAppAuth();
  if (!firebaseAppAuth) {
    return Promise.resolve({
      success: false,
      errors: ['No authentication service available.'],
      userCredential: null,
    });
  }

  let userCredentialResult = null;
  try {
    userCredentialResult = await createUserWithEmailAndPassword(firebaseAppAuth, email, password);
  } catch (e: any) {
    const firebaseErrorDisplayText: string | null =
      e instanceof FirebaseError ? getDisplayTextForFirebaseError(e) : null;
    const errors = [firebaseErrorDisplayText ?? 'Invalid credentials'];

    return {
      success: false,
      errors,
      userCredential: null,
    };
  }

  return {
    success: true,
    userCredential: userCredentialResult,
  };
};

export const requestUserLogin = async (
  email: string,
  password: string,
): Promise<RequestUserLoginWithDetails> => {
  const firebaseAppAuth = getFirebaseAppAuth();
  if (!firebaseAppAuth) {
    return Promise.resolve({
      success: false,
      errors: ['No authentication service available.'],
      userCredential: null,
    });
  }

  let userCredentialResult = null;
  try {
    userCredentialResult = await signInWithEmailAndPassword(firebaseAppAuth, email, password);
  } catch (e: any) {
    const firebaseErrorDisplayText: string | null =
      e instanceof FirebaseError ? getDisplayTextForFirebaseError(e) : null;
    const errors = [firebaseErrorDisplayText ?? 'Invalid credentials'];

    return {
      success: false,
      errors,
      userCredential: null,
    };
  }

  return {
    success: true,
    userCredential: userCredentialResult,
  };
};

export const requestUserLogout = async (): Promise<boolean> => {
  const firebaseAppAuth = getFirebaseAppAuth();
  if (!firebaseAppAuth) {
    return Promise.resolve(false);
  }

  await signOut(firebaseAppAuth);

  return true;
};

export const setAuthPersistence = async (): Promise<void> => {
  const firebaseAppAuth = getFirebaseAppAuth();
  if (!firebaseAppAuth) {
    return Promise.resolve();
  }

  return firebaseAppAuth.setPersistence(browserLocalPersistence);
};
