import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Store } from './types';
import { StoreListing } from '../../pages/store-listing';
import { useMatches } from 'react-router-dom';
import { requestStoreData } from './api';

export const DataLayer: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useState<Store | null>(null);

  // JRG: TODO - How to get this data elsewhere and pass it into here???
  const routeMatches = useMatches();

  const getStoreId = useCallback((matches: any[]): Store['id'] | null => {
    // Ex: website.com/store/123ABC
    // console.log(`JRG: TEST - matches: `, matches);
    if (matches.length < 1) {
      return null;
    }

    const [firstMatch] = matches;

    if (!firstMatch.params) {
      return null;
    }

    const storeId = firstMatch.params['storeId'] as string;

    return storeId;
  }, []);

  const routeStoreId = getStoreId(routeMatches);

  useEffect(() => {
    if (!routeStoreId) {
      if (storeData) {
        // Clear the collected data.
        setStoreData(null);
      }

      return;
    }

    if (storeData && storeData.id === routeStoreId) {
      // We already have the data for that id.
      return;
    }

    setIsLoading(true);

    requestStoreData(routeStoreId)
      .then((responseData) => {
        setStoreData(responseData);
      })
      .catch(() => {
        setStoreData(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [routeStoreId, storeData]);

  return (
    <div className="store-data-layer">
      <StoreListing isLoading={isLoading} store={storeData} />
    </div>
  );
};
