import React, { FunctionComponent } from 'react';
import { Header } from '../../templates/header';
import { PageBody } from '../../templates/page-body';
import { Store } from '../../data/store/types';

type PropTypes = {
  isLoading: boolean;
  store: Store | null;
};

export const StoreListing: FunctionComponent<PropTypes> = ({ isLoading, store }) => {
  return (
    <div className="store-listing">
      <Header />

      <PageBody>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <span>{`Store Listing for store - ID: ${store?.id} - NAME: ${store?.name}`}</span>
        )}
      </PageBody>
    </div>
  );
};
