import { Store } from '../store/types';
import { Product } from './types';

export const requestProductData = (
  storeId: Store['id'],
  productId: Product['id'],
): Promise<Product> => {
  return new Promise((promiseResolve) => {
    window.setTimeout(() => {
      promiseResolve({
        id: productId,
        name: `Data for productId ${productId}.`,
        description: 'Description.',
      });
    }, 2000);
  });
};
