import {
  FirebaseApp,
  FirebaseAppSettings,
  FirebaseOptions,
  getApps,
  initializeApp,
} from 'firebase/app';
import 'firebase/compat/functions';
import {
  FIREBASE_PROJECT_ID,
  FIREBASE_APP_CUSTOM_NAME,
  FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_API_KEY,
} from './constants';
import { Auth, getAuth } from 'firebase/auth';

// let firebaseApp = null;

// NOTE: Keep this identical to the `enum CloudFunctionFuncName` within functions/src/index.ts.
export enum CloudFunctionFuncName {
  userSignup = 'userSignup',
}

export const getCloudFunctionUrl = (funcName: CloudFunctionFuncName): string => {
  return `https://us-central1-${FIREBASE_PROJECT_ID}.cloudfunctions.net/${funcName}/`;
};

// NOTE: This is just for reference.
// export const callFunction = (funcName: string) => {
//   const callData = `rssUrl=${encodeURIComponent('https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml')}`;

//   axios.get(`${getCloudFunctionUrl(funcName)}?${callData}`)
//     .then((responseData) => {
//       return responseData.data;
//     }).catch((e) => {
//       return null;
//     });
// };

export const getFirebaseApp = (): FirebaseApp | null => {
  const initializedApps = getApps();

  const firebaseApp = initializedApps.find((entry) => entry.name === FIREBASE_APP_CUSTOM_NAME);
  if (!firebaseApp) {
    return null;
  }

  return firebaseApp;
};

export const getFirebaseAppAuth = (): Auth | null => {
  const firebaseApp = getFirebaseApp();
  if (!firebaseApp) {
    return null;
  }

  return getAuth(firebaseApp);
};

export const loadFirebase = () => {
  // Check if we were already loaded.
  const preExistingApp = getFirebaseApp();
  if (preExistingApp) {
    return;
  }

  const firebaseOptions: FirebaseOptions = {
    apiKey: FIREBASE_API_KEY,
    authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };

  const firebaseConfig: FirebaseAppSettings = {
    name: FIREBASE_APP_CUSTOM_NAME,
    automaticDataCollectionEnabled: false,
  };

  try {
    initializeApp(firebaseOptions, firebaseConfig);

    // Initialize Firebase Authentication and get a reference to the service
    // const auth = getAuth(firebaseApp);
    // console.log(`JRG: TEST - auth: `, auth);
    // */

    /*
    let features = [
      // 'auth', 
      // 'database', 
      // 'firestore',
      'functions',
      // 'messaging', 
      // 'storage', 
      // 'analytics', 
      // 'remoteConfig',
      // 'performance',
    ].filter(feature => typeof app[feature] === 'function');
    // loadEl.textContent = `Firebase SDK loaded with ${features.join(', ')}`;

    if (process.env.NODE_ENV === 'development') {
      console.log(`Firebase SDK loaded with ${features.join(', ')}`);
    }
    // */
  } catch (e) {
    console.error(e);
    // loadEl.textContent = 'Error loading the Firebase SDK, check the console.';

    if (process.env.NODE_ENV === 'development') {
      console.log('Error loading the Firebase SDK, check the console.');
    }
  }
};
