import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export interface UserState {
  value: number;
  hasCollectedLoginState: boolean;
  isLoggedIn: boolean;
  userEmail: string | null;
  displayName: string | null;
}

const initialState: UserState = {
  value: 0,
  hasCollectedLoginState: false,
  isLoggedIn: false,
  userEmail: null,
  displayName: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setHasCollectedLoginState: (state, action: PayloadAction<boolean>) => {
      state.hasCollectedLoginState = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string | null>) => {
      state.userEmail = action.payload;
    },
    setDisplayName: (state, action: PayloadAction<string | null>) => {
      state.displayName = action.payload;
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function.
export const {
  setHasCollectedLoginState,
  setLoggedIn,
  setUserEmail,
  setDisplayName,
  increment,
  decrement,
  incrementByAmount,
} = userSlice.actions;

export const getHasCollectedLoginState = (state: RootState) => state.user.hasCollectedLoginState;
export const getIsLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const getUserEmail = (state: RootState) => state.user.userEmail;
export const getDisplayName = (state: RootState) => state.user.displayName;

export const selectCount = (state: RootState) => state.user.value;

export const userReducer = userSlice.reducer;
