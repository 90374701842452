import React, { FunctionComponent, useCallback } from 'react';
import MuiButton from '@mui/material/Button';
import { Page } from '../../../templates/page';
import { Header } from '../../../templates/header';
import { useUserNotLoggedInRedirect } from '../../utils/use-user-not-logged-in-redirect';
import { requestUserLogout } from '../../../data/user/api';

export const UserSettingsPage: FunctionComponent = () => {
  useUserNotLoggedInRedirect();

  const handleClickLogoutButton = useCallback(() => {
    requestUserLogout();
  }, []);

  return (
    <Page
      className="user-settings-page"
      header={<Header />}
    >
      <div>
        <div>User Settings</div>

        <MuiButton onClick={handleClickLogoutButton}>Logout</MuiButton>
      </div>
    </Page>
  );
};
