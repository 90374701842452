import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { PageBody } from '../page-body';

type Props = {
  className?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

export const Page: FunctionComponent<Props> = ({ className, header, children, footer }) => {
  return (
    <div className={classNames('page', className)}>
      {header && <div className="header-container">{header}</div>}

      <PageBody>{children}</PageBody>

      {footer && <div className="footer-container">{footer}</div>}
    </div>
  );
};
