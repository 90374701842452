export const FIREBASE_PROJECT_ID = 'madelocale-f6fb6';
export const FIREBASE_APP_CUSTOM_NAME = 'Made Locale';
export const FIREBASE_MESSAGING_SENDER_ID = '645348920896';
// Use this number to identify your app when you contact Firebase support.
export const FIREBASE_APP_ID = '1:645348920896:web:122ed69278431222d999a5';

export const FIREBASE_API_KEY =
  process.env.NODE_ENV === 'development'
    ? 'AIzaSyAynauPSSvflJa8RxCYkNxT5Yrl7RfU5uc'
    : 'AIzaSyBrwi07XobwkmiWhJCyC8zacvEhjnGjrJI';
