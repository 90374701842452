import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import loadJs from 'loadjs';

const MAPS_BUNDLE_NAME = 'google-maps-bundle';
const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

type PropType = {
  className?: string;
  onChangeMapInstance?: (mapInstance: google.maps.Map | null) => void;
};

export const GoogleMap: FunctionComponent<PropType> = ({ className, onChangeMapInstance }) => {
  const [isLoadingViaLoadJs, setIsLoadingViaLoadJs] = useState(false);
  const [isGoogleMapsApiLoaded, setIsGoogleMapsApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const initMap = useCallback((): void => {
    const center: google.maps.LatLngLiteral = { lat: 30, lng: -110 };
    const newMap = new google.maps.Map(
      document.getElementById('google-map-container') as HTMLElement,
      {
        center,
        zoom: 11,
        styles: mapStyles,
        // Hide the Map/Satellite toggle.
        // mapTypeControl: false,
        // Hide the orange Pegman.
        streetViewControl: false,
        fullscreenControl: false,
      },
    );

    setMapInstance(newMap);
  }, []);

  // Load Google Map script.
  useEffect((): void => {
    if (loadJs.isDefined(MAPS_BUNDLE_NAME)) {
      // The bundle is already loaded.
      if (!isLoadingViaLoadJs) {
        setIsGoogleMapsApiLoaded(true);
      }

      return;
    }

    setIsLoadingViaLoadJs(true);

    (window as any).myGMapLoadedFunc = () => {
      setIsLoadingViaLoadJs(false);
      setIsGoogleMapsApiLoaded(true);
    };

    loadJs(
      `https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&callback=myGMapLoadedFunc&v=quarterly`,
      MAPS_BUNDLE_NAME,
      {
        success: function () {
          // This space intentionally left blank.
        },
        error: function (pathsNotFound: any) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`loadJs - error:`, pathsNotFound);
          }
        },
      },
    );
  }, [isLoadingViaLoadJs]);

  // Initialize the Map when able.
  useEffect((): void => {
    // Don't proceed to calling initMap() if we are in the middle of loading or have already finished loading.
    if (mapInstance || isLoadingViaLoadJs || !isGoogleMapsApiLoaded) {
      return;
    }

    initMap();
  }, [isGoogleMapsApiLoaded, initMap, mapInstance, isLoadingViaLoadJs]);

  useEffect(() => {
    if (onChangeMapInstance) {
      onChangeMapInstance(mapInstance);
    }
  }, [mapInstance, onChangeMapInstance]);

  return <div className={className} id="google-map-container" />;
};
