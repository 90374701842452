import { Store } from './types';

export const requestStoreData = (storeId: Store['id']): Promise<Store> => {
  return new Promise((promiseResolve) => {
    window.setTimeout(() => {
      promiseResolve({
        id: storeId,
        name: `Data for store ${storeId}`,
      });
    }, 2000);
  });
};
