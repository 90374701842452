import React, { FunctionComponent, useMemo } from 'react';
import { Header } from '../../templates/header';
import { PageBody } from '../../templates/page-body';
import { useRouteError } from 'react-router-dom';
import { usePageTitle } from '../utils/use-page-title';

const emoticons = [
  '(￣ω￣;)',
  '(￣～￣;)',
  '(・_・ヾ',
  '(・_・;)',
  '(¯ . ¯;)',
  '(•ิ_•ิ)?',
  'ლ(ಠ_ಠ ლ)',
  'ლ(¯ロ¯"ლ)',
  '(¯ . ¯٥)',
  '＼(º □ º l|l)/',
  '＼(〇_ｏ)／',
  '~(>_<~)',
  '(＞﹏＜)',
  'ヾ( ￣O￣)ツ',
  '(；￣Д￣)',
  '(￣□￣」)',
  '(＃￣0￣)',
  '(＃￣ω￣)',
  '(￣▽￣*)ゞ',
];

export const RouterErrorPage: FunctionComponent = () => {
  const error = useRouteError() as any;
  const errorStatusText = error['statusText'] as string;
  const errorMessage = error['message'] as string;
  const errorData = error['data'] as string;

  const emoticon = useMemo((): string => {
    const randIndex = Math.floor(Math.random() * emoticons.length);
    return emoticons[randIndex];
  }, []);

  usePageTitle(`${emoticon} - Error`);

  return (
    <div className="router-error-page">
      <Header />

      <PageBody className="error-page-body">
        <div className="error-page-header">
          <span className="emoticon">{emoticon}</span>

          <span className="message">It looks like we encountered an error.</span>
        </div>

        {errorStatusText && (
          <>
            <div>Error status:</div>

            <div className="error-message-container">
              <samp>{errorStatusText}</samp>
            </div>
          </>
        )}

        {errorMessage && (
          <>
            <div>Error message:</div>

            <div className="error-message-container">
              <samp>{errorMessage}</samp>
            </div>
          </>
        )}

        {errorData && (
          <>
            <div>Error data:</div>

            <div className="error-message-container">
              <samp>{errorData}</samp>
            </div>
          </>
        )}

        <kbd></kbd>
      </PageBody>
    </div>
  );
};
