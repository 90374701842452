import React, { FunctionComponent, useCallback, useState } from 'react';
import classNames from 'classnames';
import MuiBox from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import MuiDrawer from '@mui/material/Drawer';
import MuiIconButton from '@mui/material/IconButton';
import MuiCloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MuiAccountBoxIcon from '@mui/icons-material/AccountBox';
import { useAppSelector } from '../../data/hooks';
import { getIsLoggedIn } from '../../data/user/userSlice';
import { SignupAndLoginForm } from '../../organisms/signup-and-login-form';
import { requestUserLogout } from '../../data/user/api';
import { useNavigate } from 'react-router-dom';
import { URL_PATH_USER_SETTINGS_PAGE } from '../../pages/constants';

type PropType = {
  className?: string;
};

export const NavHeaderProfileBox: FunctionComponent<PropType> = ({ className }) => {
  const navigate = useNavigate();
  const isUserLoggedIn = useAppSelector(getIsLoggedIn);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClickLoginSignupButton = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleCloseLoginSignupDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const handleClickProfileButton = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleClickSettingsButton = useCallback(() => {
    navigate(URL_PATH_USER_SETTINGS_PAGE);
  }, [navigate]);

  const handleClickLogoutButton = useCallback(() => {
    requestUserLogout().then((success: boolean) => {
      if (success) {
        setIsDrawerOpen(false);
      }
    });
  }, []);

  return (
    <div className={classNames('nav-header-profile-box', className)}>
      {isUserLoggedIn && (
        <MuiButton onClick={handleClickProfileButton}>
          <MuiAccountBoxIcon />
        </MuiButton>
      )}

      {!isUserLoggedIn && (
        <MuiButton onClick={handleClickLoginSignupButton}>Login / Signup</MuiButton>
      )}

      <MuiDrawer
        className="nav-header-profile-box-drawer"
        open={isDrawerOpen}
        onClose={handleCloseLoginSignupDrawer}
        anchor="right"
      >
        <MuiBox sx={{ width: 340 }} role="presentation" className="drawer-content">
          <div className="drawer-header">
            <MuiIconButton className="drawer-close-button" onClick={handleCloseLoginSignupDrawer}>
              <MuiCloseRoundedIcon />
            </MuiIconButton>
          </div>

          <div
            className={classNames('drawer-content-body', {
              'is-visible': isDrawerOpen,
              'is-hidden': !isDrawerOpen,
            })}
          >
            {!isUserLoggedIn && <SignupAndLoginForm />}

            {isUserLoggedIn && (
              <>
                <div>
                  <MuiButton onClick={handleClickSettingsButton}>Settings</MuiButton>
                </div>

                <div>
                  <MuiButton onClick={handleClickLogoutButton}>Logout</MuiButton>
                </div>
              </>
            )}
          </div>
        </MuiBox>
      </MuiDrawer>
    </div>
  );
};
