import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Homepage } from './pages/homepage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { canAccessSite } from './data/utils';
import { getFirebaseAppAuth, loadFirebase } from './firebase/firebase';
import { DataLayer as StoreListingDataLayer } from './data/store/data-layer';
import { DataLayer as ProductListingDataLayer } from './data/product/data-layer';
import { RouterErrorPage } from './pages/router-error-page';
import { User, onAuthStateChanged } from 'firebase/auth';
import MyPackageInfo from '../package.json';
import {
  setLoggedIn,
  setUserEmail,
  setDisplayName,
  setHasCollectedLoginState,
} from './data/user/userSlice';
import { useAppDispatch } from './data/hooks';
import { UserGeneralPage } from './pages/user/user-general';
import { UserSettingsPage } from './pages/user/user-settings';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createMuiAppTheme } from './pages/utils';
import { URL_PATH_FRAGMENT_STORE, URL_PATH_FRAGMENT_STORE_PRODUCT, URL_PATH_HOMEPAGE_PAGE, URL_PATH_USER_PAGE, URL_PATH_USER_SETTINGS_PAGE } from './pages/constants';

const router = createBrowserRouter([
  {
    path: URL_PATH_HOMEPAGE_PAGE,
    element: <Homepage />,
    errorElement: <RouterErrorPage />,
  },
  {
    path: `${URL_PATH_FRAGMENT_STORE}/:storeId`,
    element: <StoreListingDataLayer />,
  },
  {
    path: `${URL_PATH_FRAGMENT_STORE}/:storeId/${URL_PATH_FRAGMENT_STORE_PRODUCT}/:productId`,
    element: <ProductListingDataLayer />,
  },
  {
    path: URL_PATH_USER_PAGE,
    element: <UserGeneralPage />,
  },
  {
    path: URL_PATH_USER_SETTINGS_PAGE,
    element: <UserSettingsPage />,
  },
]);

function App() {
  const dispatch = useAppDispatch();

  const canAccess = canAccessSite();
  const [hasLoadedFirebase, setHasLoadedFirebase] = useState(false);

  const muiAppTheme = useMemo(() => {
    return createMuiAppTheme();
  }, []);

  useEffect(() => {
    console.log(`Version: ${MyPackageInfo.version}`);
  }, []);

  const handleAuthStateChanged = useCallback(
    (user: User | null): void => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        dispatch(setHasCollectedLoginState(true));
        dispatch(setLoggedIn(true));
        dispatch(setUserEmail(user.email));
        dispatch(setDisplayName(user.displayName));
      } else {
        // User is signed out.
        dispatch(setHasCollectedLoginState(true));
        dispatch(setLoggedIn(false));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!canAccess || hasLoadedFirebase) {
      return;
    }

    setHasLoadedFirebase(true);
    loadFirebase();

    const firebaseAppAuth = canAccess ? getFirebaseAppAuth() : null;
    if (firebaseAppAuth) {
      onAuthStateChanged(firebaseAppAuth, handleAuthStateChanged);
    }
  }, [canAccess, dispatch, handleAuthStateChanged, hasLoadedFirebase]);

  if (!canAccess) {
    return <span>X</span>;
  }

  return (
    <div className="App">
      <MuiThemeProvider theme={muiAppTheme}>
        <RouterProvider router={router} />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
