import React, { FunctionComponent, useCallback } from 'react';
import MuiButton from '@mui/material/Button';
import { NavHeaderProfileBox } from '../../molecules/nav-header-profile-box';
import { APP_DISPLAY_NAME } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import { URL_PATH_HOMEPAGE_PAGE } from '../../pages/constants';

export const Header: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleClickAppName = useCallback(() => {
    navigate(URL_PATH_HOMEPAGE_PAGE);
  }, [navigate]);

  return (
    <div className="header">
      <div className="left-side">
        <MuiButton onClick={handleClickAppName}>{APP_DISPLAY_NAME}</MuiButton>
      </div>

      <div className="right-side">
        <NavHeaderProfileBox className="header-profile-box" />
      </div>
    </div>
  );
};
