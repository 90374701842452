import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../data/hooks';
import { getHasCollectedLoginState, getIsLoggedIn } from '../../data/user/userSlice';
import { URL_PATH_LOGIN_PAGE } from '../constants';

export const useUserNotLoggedInRedirect = () => {
  const navigate = useNavigate();

  const hasCollectedLoginState = useAppSelector(getHasCollectedLoginState);
  const isUserLoggedIn = useAppSelector(getIsLoggedIn);

  useEffect(() => {
    if (hasCollectedLoginState && !isUserLoggedIn) {
      navigate(URL_PATH_LOGIN_PAGE);
    }
  }, [hasCollectedLoginState, isUserLoggedIn, navigate]);

  return null;
};
