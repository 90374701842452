import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Product } from './types';
import { ProductListing } from '../../pages/product-listing';
import { useMatches } from 'react-router-dom';
import { requestProductData } from './api';
import { StoreAndProductId } from '../store/types';

export const DataLayer: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState<Product | null>(null);

  // JRG: TODO - How to get this data elsewhere and pass it into here???
  const routeMatches = useMatches();

  const getStoreAndProductId = useCallback((matches: any[]): StoreAndProductId | null => {
    // Ex: website.com/store/123ABC/prod/789XYZ
    if (matches.length < 1) {
      return null;
    }

    const [firstMatch] = matches;

    if (!firstMatch.params) {
      return null;
    }

    const storeId = firstMatch.params['storeId'] as string;
    const productId = firstMatch.params['productId'] as string;

    return {
      storeId,
      productId,
    };
  }, []);

  const routeStoreAndProductId = getStoreAndProductId(routeMatches);

  useEffect(() => {
    if (!routeStoreAndProductId) {
      if (productData) {
        // Clear the collected data.
        setProductData(null);
      }

      return;
    }

    if (productData && productData.id === routeStoreAndProductId.productId) {
      // We already have the data for that id.
      return;
    }

    console.log(
      `JRG: TEST - requestProductData() - routeStoreAndProductId, productData: `,
      routeStoreAndProductId,
      productData,
    );
    setIsLoading(true);

    requestProductData(routeStoreAndProductId.storeId, routeStoreAndProductId.productId)
      .then((responseData) => {
        console.log(`JRG: TEST - requestProductData() - then() - responseData: `, responseData);
        setProductData(responseData);
      })
      .catch(() => {
        setProductData(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [routeStoreAndProductId, productData]);

  if (
    !routeStoreAndProductId ||
    !routeStoreAndProductId.storeId ||
    !routeStoreAndProductId.productId
  ) {
    return null;
  }

  return (
    <div className="store-data-layer">
      <ProductListing isLoading={isLoading} product={productData} />
    </div>
  );
};
