import { Theme as MuiTheme, createTheme as muiCreateTheme } from '@mui/material/styles';

// Ref: https://mui.com/material-ui/customization/theming/
export const createMuiAppTheme = (): MuiTheme => {
  const appTheme = muiCreateTheme({
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  });

  return appTheme;
};
