import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { requestUserLogin, requestUserSignup, setAuthPersistence } from '../../data/user/api';
import MuiButton from '@mui/material/Button';
import MuiFormControl from '@mui/material/FormControl';
import MuiInputLabel from '@mui/material/InputLabel';
import MuiInput from '@mui/material/Input';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiAlert from '@mui/material/Alert';
import MuiCircularProgress from '@mui/material/CircularProgress';

const VALID_EMAIL_REGEX = /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}$/gm;

enum FlowStep {
  enterEmail = 'enterEmail',
  enterPassword = 'enterPassword',
}

type PropType = {
  className?: string;
};

export const SignupAndLoginForm: FunctionComponent<PropType> = ({ className }) => {
  const [flowStep, setFlowStep] = useState(FlowStep.enterEmail);
  const [isNewSignup, setIsNewSignup] = useState(false);

  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isRequestMessageShown, setIsRequestMessageShown] = useState(false);
  const [requestMessage, setRequestMessage] = useState<string | React.ReactElement>('');

  const headerLabel = useMemo((): string => {
    if (flowStep === FlowStep.enterEmail) {
      return 'Login / Signup';
    }

    return isNewSignup ? 'Signup' : 'Login';
  }, [flowStep, isNewSignup]);

  const checkIsValidEmail = useCallback((emailToCheck: string): boolean => {
    const matches = emailToCheck.match(VALID_EMAIL_REGEX);

    if (matches && matches.length > 0) {
      return true;
    }

    return false;
  }, []);

  const handleChangeEmail = useCallback(
    (e: any): void => {
      const newValue = e.target.value;

      setIsEmailValid(checkIsValidEmail(newValue));
      setEmail(newValue);
    },
    [checkIsValidEmail],
  );

  const handleClickBackToEmailInput = useCallback(() => {
    // Go back to the Enter-Email step if we aren't there.
    if (flowStep !== FlowStep.enterEmail) {
      setIsRequestMessageShown(false);
      setFlowStep(FlowStep.enterEmail);
    }
  }, [flowStep]);

  const handleChangePassword = useCallback((e: any): void => {
    setPassword(e.target.value);
  }, []);

  const autoFocusPasswordInputAfterMoment = useCallback(() => {
    // Wait a moment then focus on the password input.
    window.setTimeout(() => {
      const inputElement = document.querySelector('input#password-input');

      if (inputElement) {
        (inputElement as any).focus();
      }
    }, 100);
  }, []);

  const handleClickSignupButton = useCallback(() => {
    setIsNewSignup(true);
    setPassword('');
    setFlowStep(FlowStep.enterPassword);

    autoFocusPasswordInputAfterMoment();
  }, [autoFocusPasswordInputAfterMoment]);

  const handleClickLoginButton = useCallback(() => {
    setIsNewSignup(false);
    setPassword('');
    setFlowStep(FlowStep.enterPassword);

    autoFocusPasswordInputAfterMoment();
  }, [autoFocusPasswordInputAfterMoment]);

  const handleKeyForEmailInput = useCallback(
    (e: any): void => {
      if (e.key === 'Enter') {
        e.preventDefault();

        if (isEmailValid) {
          handleClickLoginButton();
        }
      }
    },
    [handleClickLoginButton, isEmailValid],
  );

  const handleFormSubmitForUserSignup = useCallback(async () => {
    setIsRequestMessageShown(false);

    setIsProcessingRequest(true);
    const requestResult = await requestUserSignup(email, password);
    setIsProcessingRequest(false);

    if (requestResult.success) {
      setRequestMessage('');
    } else {
      const errors = requestResult.errors ?? [];

      setRequestMessage(errors.join(','));
      setIsRequestMessageShown(true);
    }
  }, [email, password]);

  const handleFormSubmitForLogin = useCallback(async () => {
    setIsRequestMessageShown(false);

    setIsProcessingRequest(true);
    const requestResult = await requestUserLogin(email, password);
    setIsProcessingRequest(false);

    if (requestResult.success) {
      setRequestMessage('');
    } else {
      const errors = requestResult.errors ?? [];

      setRequestMessage(errors.join(','));
      setIsRequestMessageShown(true);
    }
  }, [email, password]);

  const handleFormSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      await setAuthPersistence();

      if (isNewSignup) {
        await handleFormSubmitForUserSignup();
      } else {
        await handleFormSubmitForLogin();
      }
    },
    [handleFormSubmitForLogin, handleFormSubmitForUserSignup, isNewSignup],
  );

  const BackToEmailElement = useMemo(() => {
    return (
      <MuiButton
        className="is-clickable back-to-email-input-link"
        onClick={handleClickBackToEmailInput}
      >
        Back
      </MuiButton>
    );
  }, [handleClickBackToEmailInput]);

  const isSubmitButtonDisabled = isProcessingRequest || !password;

  return (
    <div className={classNames('signup-and-login-form', className)}>
      <form className="signup-and-login-form-form" onSubmit={handleFormSubmit}>
        <div className="header-label-container">
          <MuiFormLabel className="user-select-none">{headerLabel}</MuiFormLabel>
        </div>

        <MuiFormControl className="form-control">
          <MuiInputLabel htmlFor="email-input">Email:</MuiInputLabel>

          <MuiInput
            id="email-input"
            onChange={handleChangeEmail}
            disabled={flowStep !== FlowStep.enterEmail}
            onKeyDown={handleKeyForEmailInput}
          />
        </MuiFormControl>

        {flowStep === FlowStep.enterPassword && (
          <MuiFormControl className="form-control">
            <MuiInputLabel htmlFor="password-input">Password:</MuiInputLabel>

            <MuiInput id="password-input" type="password" onChange={handleChangePassword} />
          </MuiFormControl>
        )}

        {flowStep === FlowStep.enterEmail && (
          <div className="login-or-signup-buttons-container">
            <MuiButton onClick={handleClickLoginButton} disabled={!isEmailValid}>
              Login...
            </MuiButton>

            <MuiButton onClick={handleClickSignupButton} disabled={!isEmailValid}>
              Signup...
            </MuiButton>
          </div>
        )}

        {flowStep === FlowStep.enterPassword && (
          <div className="login-or-signup-buttons-container">
            {isProcessingRequest ? (
              <MuiCircularProgress color="secondary" />
            ) : (
              <>
                {isNewSignup && BackToEmailElement}

                <MuiButton type="submit" className="final-button" disabled={isSubmitButtonDisabled}>
                  {isNewSignup ? 'Signup' : 'Login'}
                </MuiButton>

                {!isNewSignup && BackToEmailElement}
              </>
            )}
          </div>
        )}
      </form>

      {isRequestMessageShown && <MuiAlert severity="error">{requestMessage}</MuiAlert>}
    </div>
  );
};
