import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const PageBody: FunctionComponent<Props> = ({ className, children }) => {
  return <div className={classNames('page-body', className)}>{children}</div>;
};
