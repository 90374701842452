import React, { FunctionComponent } from 'react';
import { Page } from '../../../templates/page';
import { Header } from '../../../templates/header';
import { useUserNotLoggedInRedirect } from '../../utils/use-user-not-logged-in-redirect';

export const UserGeneralPage: FunctionComponent = () => {
  useUserNotLoggedInRedirect();

  return (
    <Page className="user-general-page" header={<Header />}>
      <div>user-general-page</div>
    </Page>
  );
};
