import React, { FunctionComponent } from 'react';
import { Header } from '../../templates/header';
import { PageBody } from '../../templates/page-body';
// import { useMatches } from 'react-router-dom';
// import { StoreAndProductId } from '../../data/store/types';
import { Product } from '../../data/product/types';

type PropTypes = {
  isLoading: boolean;
  product: Product | null;
};

export const ProductListing: FunctionComponent<PropTypes> = ({ isLoading, product }) => {
  return (
    <div className="product-listing">
      <Header />

      <PageBody>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <span>
            {`Product Listing for product - id: ${product?.id} - name: ${product?.name} - description: ${product?.description}.`}
          </span>
        )}
      </PageBody>
    </div>
  );
};
