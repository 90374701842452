import { LatLngCoord } from './types';

export const GoogleMapsLatLngToLatLngCoord = (gmLatLng: google.maps.LatLng): LatLngCoord => {
  return {
    lat: gmLatLng.lat(),
    lng: gmLatLng.lng(),
  };
};

// Four value places are good enough for most applications. (https://xkcd.com/2170/)
export const RoundLatLngCoord = (latLngCoord: LatLngCoord, valuePlaces = 4): LatLngCoord => {
  const tenPower = Math.pow(10, valuePlaces);

  const roundedLatLng: LatLngCoord = {
    lat: Math.round(latLngCoord.lat * tenPower) / tenPower,
    lng: Math.round(latLngCoord.lng * tenPower) / tenPower,
  };

  return roundedLatLng;
};
