import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import MuiButton from '@mui/material/Button';
import debounce from 'lodash/debounce';
import { Header } from '../../templates/header';
import { GoogleMap } from '../../organisms/google-map';
import { LatLngCoord } from '../../data/map/types';
import { GoogleMapsLatLngToLatLngCoord, RoundLatLngCoord } from '../../data/map/utils';
import { Page } from '../../templates/page';

export const Homepage: FunctionComponent = () => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [collectedMapCenterLatLng, setCollectedMapCenterLatLng] = useState<LatLngCoord | null>(
    null,
  );

  const [hasUsedUserLocation, setHasUsedUserLocation] = useState(false);
  const [userLocation, setUserLocation] = useState<LatLngCoord | null>(null);

  const onSuccessGetCurrentPosition = useCallback((position: GeolocationPosition) => {
    const user_position: LatLngCoord = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setUserLocation(user_position);

    return user_position;
  }, []);

  const getUserLocation = useCallback((): void => {
    if (!navigator.geolocation) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`Geolocation not available when trying to acquire user's location.`);
      }

      return;
    }

    navigator.geolocation.getCurrentPosition(onSuccessGetCurrentPosition);
  }, [onSuccessGetCurrentPosition]);

  // Center on the user's location if able.
  useEffect((): void => {
    if (hasUsedUserLocation || !mapInstance || !userLocation) {
      return;
    }

    mapInstance.setCenter({
      lat: userLocation.lat,
      lng: userLocation.lng,
    });
    setHasUsedUserLocation(true);
  }, [hasUsedUserLocation, userLocation, mapInstance]);

  const handleChangeMapInstance = useCallback((newMapInstance: google.maps.Map | null) => {
    setMapInstance(newMapInstance);
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  const handleClickCenterButton = useCallback(() => {
    if (!mapInstance || !collectedMapCenterLatLng) {
      return;
    }

    mapInstance.setCenter({
      lat: collectedMapCenterLatLng.lat,
      lng: collectedMapCenterLatLng.lng,
    });
  }, [mapInstance, collectedMapCenterLatLng]);

  const handleCenterChanged = useCallback((): void => {
    const mapCenter = mapInstance?.getCenter();
    if (!mapCenter) {
      return;
    }

    const latLngCoord = GoogleMapsLatLngToLatLngCoord(mapCenter);
    const latLngCoordToSave = RoundLatLngCoord(latLngCoord, 4);
    setCollectedMapCenterLatLng(latLngCoordToSave);
  }, [mapInstance]);

  const handleCenterChangedFunc = debounce(handleCenterChanged, 250);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    const centerChangedListener = mapInstance.addListener(
      'center_changed',
      handleCenterChangedFunc,
    );

    return () => {
      google.maps.event.removeListener(centerChangedListener);
    };
  }, [handleCenterChangedFunc, mapInstance]);

  return (
    <Page
      header={<Header />}
    >
      <>
        <div>
          {mapInstance &&
            collectedMapCenterLatLng &&
            `${collectedMapCenterLatLng.lat}, ${collectedMapCenterLatLng.lng}`}
          <MuiButton onClick={handleClickCenterButton}>Center</MuiButton>
        </div>

        <div className="map-container">
          <GoogleMap
            className="homepage-google-map"
            onChangeMapInstance={handleChangeMapInstance}
          />
        </div>
      </>
    </Page>
  );
};
